import React from "react"

class InsuranceEligibilty extends React.Component {
  render() {
    return (
      <div className="insurance-eligibility">
        <i>
          Insurance eligibility is based on specific member plan details.
          Submitting this information does not obligate payment. Please provide
          active and accurate insurance information. Failure to do so may result
          in you being responsible for self-pay. Additionally, you must disclose
          your coordination of benefits. Cortica will make every effort to
          determine eligibility and provide a cost estimate prior to services
          rendered. However, we cannot guarantee payment or verify that the
          eligibility of benefits conveyed to us or to you by your carrier will
          be accurate or complete. Payment of benefits is subject to all terms,
          conditions, limitations, and exclusions of the member&apos;s contract
          at the time of service.
        </i>
      </div>
    )
  }
}
export default InsuranceEligibilty
